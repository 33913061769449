import Vue from 'vue'
import Vuex from 'vuex'
import travel_list from './modules/travel_list'
import guider from './modules/guider'
import notification from './modules/notification'
import feedback from './modules/feedback'
import company from './modules/company'
import wode from './modules/wode'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    travel_list,
    guider,
    notification,
    feedback,
    company,
    wode
  }
})