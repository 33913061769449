import apis from "../../services/apis"

const state = () => ({
  currentGuider: {
    guide: {
      avatar: ""
    }
  }
})

const getters = {
  getCurrentGuider: state => state.currentGuider
}

const actions = {
  async getGuiderById({commit}, id) {
    let res = await apis.guide(id)
    if(res.status === 200) {
      if(res.data.travel_company_id != null) {
        let companyRes = await apis.agency(res.data.travel_company_id)
        if(companyRes.status === 200) {
          res.data['travel_company'] = companyRes.data.agency
        } 
      } else {
        res.data['travel_company'] = {}
      }
      commit('setCurrentGuider', res.data)
      return res
    }
  },
  async updateGuideInfo({commit}, data) {
    commit('setCurrentGuider', data)
  }
}

const mutations = {
  setCurrentGuider(state, data) {
    state.currentGuider = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced:true
}