import api from '@/services/apis.js'
import cookies from '@/utils/cookies'

const state = () => ({
  personInfo: {
    user: {
      avatar: "",
    },
    tourist: {
      gender: ""
    }
  },
  fmtPersonInfo: {}
})

const getters = {
  getPersonInfo: state => state.personInfo,
  getFmtPersonInfo: state => state.fmtPersonInfo
}

const actions = {
  async getPersonInfo({commit}) {
    let res = await api.visitor_info()
    if(res.status === 200) {
      commit('setPersonInfo', res.data)
      cookies.set('mobile', res.data.user.mobile)
      const fmtPersonInfo = {
        mobile: res.data.user.mobile,
        username: res.data.user.username,
        gender: res.data.tourist.gender,
        avatar: res.data.user.avatar,
        birth_date: res.data.tourist.birth_date,
        id_card: res.data.tourist.id_card
      }
      commit('setFmtPersonInfo', fmtPersonInfo)
      return res.data
    }
  },
  async syncPersonInfo({commit}, data) {
    commit('setPersonInfo', data)
  },
  async resetState({ commit }) {
    commit('resetState')
  },
}

const mutations = {
  setPersonInfo(state, data) {
    state.personInfo = data
  },
  setFmtPersonInfo(state, data) {
    state.fmtPersonInfo = data
  },
  resetState(state) {
    state.personInfo = {}
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}