import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import router from './router'
import apis from '@/services/apis'
import "@/svgIcon/index.js"
import store from './store/index'
import AmapVue from '@amap/amap-vue';
Vue.use(Vant);

AmapVue.config.key = '74bfabca263c5d85fc9d7459d275457e'
AmapVue.config.plugins = ['Amap.Geolocation', 'Amap.Driving']
Vue.use(AmapVue)
Vue.config.productionTip = false
Vue.prototype.$apis = apis;
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
