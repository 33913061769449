export function getFormatDate(dateStr) {
  const date = new Date(dateStr);
  return `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
}

export function calculateDateDiff(start_date_str, end_date_str) {
  const start_date = new Date(start_date_str)
  const end_date = new Date(end_date_str)

  return end_date - start_date
}