import api from '@/services/apis.js'
import {getFormatDate} from '@/utils/common.utils'

const state = () => ({
  feedback_tags: [],
  feedbacks: [],
  current_feedback: {}
})

const getters = {
  get_guider_feedback_tags: state => {
    return state.feedback_tags.filter(tags => tags.type == 'guide')
  },
  feedback_tags: state => state.feedback_tags,
  feedbacks: state => state.feedbacks,
  currentFeedback: state => state.current_feedback
}

const actions = {
  async get_feedback_tags({commit}) {
    let res = await api.feedback_tags()
    if(res) {
      commit('set_feed_back_tags', res)
    }
  },
  async get_feedbacks({commit}, page=1) {
    let res = await api.feedbacks({page: page, feedback_type: 1})
    if(res.status === 200) {
      let data = res.data
      if(page == 1) {
        commit('set_feedbacks', data)
      }
      res.data = data
      return res
    }
  },
  async get_feedback_by_id({commit}, id) {
    let feedbackRes = await api.feedback_info(id)
    if(feedbackRes.status === 200) {
      let item = feedbackRes.data
      commit('set_current_feedback', item)
    }
  },
  async sync_feedbacks({state, commit}, data) {
    let feedbacks = state.feedbacks
    for(let i=0;i<data.length;i++){
      const item = data[i]
      feedbacks.push(item)
    }
    commit('set_feedbacks', feedbacks)
  }
}

const mutations = {
  set_feed_back_tags(state, data) {
    state.feedback_tags = data
  },
  set_feedbacks(state, data) {
    state.feedbacks = data
  },
  set_current_feedback(state, data) {
    state.current_feedback = data
  }
}

function getFeedbackTagsByTypeAndIds(tags, type, ids) {
  let result = []
  tags.data.forEach((element) => {
    if(element.type === type) {
      result = element.value.filter(tag => ids.indexOf(tag.id) >= 0)
    }
  })
  return result
}


export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}