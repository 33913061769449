import apis from '@/services/apis.js'

const state = () => ({
  currentCompany: {}
})

const getters = {
  getCurrentCompany: state => state.currentCompany
}

const actions = {
  async getCompanyById({commit}, id) {
    let res = await apis.agency(id)
    if(res.status === 200) {
      commit('setCurrentCompany', res.data.agency)
    }
  }
}

const mutations = {
  setCurrentCompany(state, data) {
    state.currentCompany = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced:true
}