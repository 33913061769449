import Vue from 'vue'
import cookies from "../utils/cookies";
import VueRouter from 'vue-router'
import { Toast } from 'vant';

const Login = () =>
	import('../views/Login/index.vue')
Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Layout',
	redirect: "/home",
	meta: { auth: true, name: '我的', keepAlive: true },
	component: () =>
		import('@/layout/index'),
	children: [{
		path: 'tour',
		name: 'Tour',
		redirect: { name: "TourMain" },
		meta: { auth: true, name: '我的', keepAlive: true },
		component: () =>
			import('@/views/tour/index'),
		children: [{
			path: 'main',
			name: 'TourMain',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/tour/main')
		},
		{
			path: 'info/:id',
			name: 'TourInfo',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/tour/info')
		},
		{
			path: 'item/:id',
			name: 'TourItem',
			meta: { auth: true, name: '行程详情', keepAlive: true },
			component: () =>
				import('@/views/tour/item')
		},
		{
			path: 'backup/:id',
			name: 'TourBackup',
			meta: { auth: true, name: '行程上报', keepAlive: true },
			component: () =>
				import('@/views/tour/backup')
		},
		{
			path: 'backupInfo/:id',
			name: 'TourBackupInfo',
			meta: { auth: true, name: '备案信息', keepAlive: true },
			component: () =>
				import('@/views/tour/backup.info.vue')
		},
		]
	},
	{
		path: 'guider',
		name: 'Guider',
		redirect: { name: "guiderInfo" },
		meta: { auth: true, name: '我的', keepAlive: true },
		component: () =>
			import('@/views/tour/index'),
		children: [{
			path: 'info/:id',
			name: 'guiderInfo',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/guider/info')
		},
		{
			path: 'companyInfo/:id',
			name: 'guiderInfo',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/guider/company.info')
		},
		]
	},
	{
		path: 'home',
		name: 'Home',
		meta: { auth: true, name: '我的', keepAlive: true },
		component: () =>
			import('@/views/Home')
	},
	{
		path: 'scan',
		name: 'Scan',
		meta: { auth: true, name: '扫一扫', keepAlive: true },
		component: () =>
			import('@/views/Scan')
	},
	{
		path: 'notification',
		name: 'notification',
		redirect: { name: "notificationMain" },
		meta: { auth: true, name: '我的', keepAlive: true },
		component: () =>
			import('@/views/notification/index'),
		children: [{
			path: 'main',
			name: 'notificationMain',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/notification/main')
		},
		{
			path: 'info/:id',
			name: 'notificationInfo',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/notification/info')
		},
		{
			path: 'feedback/:id',
			name: 'notificationFeedback',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/notification/feedback')
		},
		]
	},
	{
		path: 'baojing',
		name: 'Baojing',
		meta: { auth: true, name: '我的', keepAlive: true },
		component: () =>
			import('@/views/baojing/index')
	},
	{
		path: 'wode',
		name: 'Wode',
		meta: { auth: true, name: '我的', keepAlive: true },
		redirect: { name: "wodeMain" },
		component: () =>
			import('@/views/wode/index'),
		children: [{
			path: 'main',
			name: 'wodeMain',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/wode/main')
		},
		{
			path: 'edit',
			name: 'wodeEdit',
			meta: { auth: true, name: '我的', keepAlive: true },
			component: () =>
				import('@/views/wode/edit')
		},
		{
			path: 'changePassword',
			name: 'wodePassword',
			meta: { auth: true, name: '修改密码', keepAlive: true },
			component: () =>
				import('@/views/wode/change.password')
		}
		]
	},
	{
		path: 'anquan',
		name: 'anquan',
		meta: { auth: true, name: '安全防护', keepAlive: true },
		redirect: { name: "anquanMain" },
		component: () =>
			import('@/views/wode/index'),
		children: [{
			path: 'main',
			name: 'anquanMain',
			meta: { auth: true, name: '安全防护', keepAlive: true },
			component: () =>
				import('@/views/anquan/main')
		},
		{
			path: 'info/:id',
			name: 'anquanInfo',
			meta: { auth: true, name: '安全防护信息详情', keepAlive: true },
			component: () =>
				import('@/views/anquan/info')
		},
		]
	},
	{
		path: 'xinxi',
		name: 'xinxi',
		meta: { auth: true, name: '信息查询', keepAlive: true },
		component: () =>
			import('@/views/xinxi/index'),
		redirect: { name: "xinxiMain" },
		children: [{
			path: 'main',
			name: 'xinxiMain',
			meta: { auth: true, name: '信息查询', keepAlive: true },
			component: () =>
				import('@/views/xinxi/main')
		},
		{
			path: 'map',
			name: 'xinxiMap',
			meta: { auth: true, name: '信息查询', keepAlive: true },
			component: () =>
				import('@/views/xinxi/map')
		},],
	},
	{
		path: 'tongxun',
		name: 'tongxun',
		meta: { auth: true, name: '信息查询', keepAlive: true },
		component: () =>
			import('@/views/tongxun/index'),
		redirect: { name: "tongxunMain" },
		children: [{
			path: 'main',
			name: 'tongxunMain',
			meta: { auth: true, name: '信息查询', keepAlive: true },
			component: () =>
				import('@/views/tongxun/main')
		},
		{
			path: 'map',
			name: 'tongxunMap',
			meta: { auth: true, name: '信息查询', keepAlive: true },
			component: () =>
				import('@/views/tongxun/map')
		},],
	},
	{
		path: 'weather',
		name: 'weather',
		meta: { auth: true, name: '天气', keepAlive: true },
		component: () => import('@/views/weather/index'),
	},
	{
		path: 'yiqing',
		name: 'yiqing',
		meta: { auth: true, name: '疫情', keepAlive: true },
		redirect: { name: "yiqingMain" },
		component: () =>
			import('@/views/yiqing/index'),
		children: [{
			path: 'main',
			name: 'yiqingMain',
			meta: { auth: true, name: '疫情信息' },
			component: () =>
				import('@/views/yiqing/main')
		},
		{
			path: 'area',
			name: 'yiqingArea',
			meta: { auth: true, name: '疫情信息' },
			component: () =>
				import('@/views/yiqing/area.detail')
		},
		{
			path: 'riskarea',
			name: 'yiqingRiskarea',
			meta: { auth: true, name: '风险地区' },
			component: () =>
				import('@/views/yiqing/risk.area')
		},

		],
	},
	{
		path: 'tousu',
		name: 'tousu',
		meta: { auth: true, name: '投诉', keepAlive: true },
		redirect: { name: "tousuMain" },
		component: () =>
			import('@/views/tousu/index'),
		children: [{
			path: 'main',
			name: 'tousuMain',
			meta: { auth: true, name: '投诉信息' },
			component: () =>
				import('@/views/tousu/main')
		},
		{
			path: 'edit',
			name: 'tousuEdit',
			meta: { auth: true, name: '投诉内容' },
			component: () =>
				import('@/views/tousu/edit')
		},
		{
			path: 'info/:id',
			name: 'tousuinfo',
			meta: { auth: true, name: '投诉详情' },
			component: () =>
				import('@/views/tousu/info')
		},
		],
	}
	]
},
{
	path: '/login',
	name: 'Login',
	meta: { auth: false, name: '登录', keepAlive: true },
	component: Login
},
{
	path: '/register',
	name: 'register',
	meta: { auth: false, name: '注册', keepAlive: true },
	component: () =>
		import('@/views/register/index'),
},

{
	path: '/agreement',
	name: 'agreement',
	meta: { auth: true, name: '用户协议' },
	component: () =>
		import('@/views/agreementPrivacy/agreement'),
},
{
	path: '/privacy',
	name: 'privacy',
	meta: { auth: true, name: '隐私条款' },
	component: () =>
		import('@/views/agreementPrivacy/privacy'),
},
]

const router = new VueRouter({
	mode: "history",
	routes
})


// router.beforeEach((to, from, next) => {

//   next();
// });
router.beforeEach((to, from, next) => {
	const publicPages = ['/login', '/register', '/agreement', '/privacy']
	if (publicPages.includes(to.path)) {
		cookies.remove('token')
	}
	const authRequired = !publicPages.includes(to.path)

	const loggedIn = cookies.get('token')

	if (to.meta.auth) {
		if (authRequired && !loggedIn) {
			Toast('您还未登录，请登录!')
			return next('/login')
		}
	}
	next()
})


export default router