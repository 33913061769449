import api from '@/services/apis.js'

const state = () => ({
  notifications: [],
  currentNotification: {
    notification: {
      title: ""
    }
  }
})

const getters = {
  getAllNotifications: state => state.notifications,
  getCurrentNotification: state => state.currentNotification
}

const actions = {
  async getAllNotifications({commit}, param) {
    let res = await api.notifications(param.type, param.page)
    if(res.status === 200) {
      if(param.page == 1) {
        commit('setNotifications', res.data)
      }
      return res
    }
  },
  async getNotificationById({commit}, id) {
    let res = await api.notifications_show(id)
    if(res.status === 200) {
      commit('setCurrentNotification', res)
    }
  },
  async syncNotifications({state, commit}, data) {
    let currentData = state.notifications
    for(let i=0;i<data.length;i++) {
      currentData.push(data[i])
    }
    commit('setNotifications', currentData)
  },
}

const mutations = {
  setNotifications(state, data) {
    state.notifications = data
  },
  setCurrentNotification(state, data) {
    state.currentNotification = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}