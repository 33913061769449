import api from '@/services/apis.js'

const default_page_info = {
    total_count: 0,
    total_pages: 0,
    current_page: 0,
    next_page: null
}

const state = () => ({
    travel_lists: [],
    unStartedTours:[],
    onGoingTours: [],
    finishedTours: [],
    canceledTours: [],
    current_travel_list_info: {
        travel_checkins: [],
        travel_plan:{
            plan_name: ""
        }
    },
    current_travel_list_nodes: [],
    current_travel_checkins: [],
    current_backup_info: {
        user: {
            username: ""
        }
    },
    page_info: default_page_info
})

const getters = {
    allTours: state => state.travel_lists,
    onGoingTours: state => state.onGoingTours,
    unStartedTours: state => state.unStartedTours,
    finishedTours: state => state.finishedTours,
    canceledTours: state => state.canceledTours,
    currentTravelList: state => {
        return state.current_travel_list_info
    },
    currentTravelListNodes: state => {
        return state.current_travel_list_nodes
    },
    currentTravelCheckins: state => {
        return state.current_travel_checkins
    },
    currentTourBackup: state => state.current_backup_info
}

const actions = {
    async getAllTravelLists({ commit }, status, page=1) {
        let res = await api.travel_lists(status)
        if (res.status === 200) {
            commit('setPageInfo', {
                total_count: res.total_count,
                total_pages: res.total_pages,
                current_page: res.current_page,
                next_page: res.next_page
            })
            switch(status) {
                case 0: 
                    commit('setUnStartedTours', res.data)
                    break
                case 1: 
                    commit('setOnGoingTours', res.data)
                    break
                case 2: 
                    commit('setFinishedTours', res.data)
                    break
                case 3:
                    commit('setCanceledTours', res.data)
                    break
            }
        }
        if(page == 1) {
            commit('setAllTravelLists', res.data)
        }
        return res
    },
    async syncTravelList({state, commit}, data) {
        let lists = state.travel_lists
        for(let i=0;i<data.length;i++){
        const item = data[i]
            lists.push(item)
        }
        commit('setAllTravelLists', lists)
    },
    async getTravelListInfoById({ commit }, id) {
        let res = await api.travel_lists_info_by_id(id)
        if (res.status === 200) {
            for(let i=0;i<res.data.travel_list_nodes.length;i++) {
                let node = res.data.travel_list_nodes[i]
                node.start_time = node.start_time != null?node.start_time.slice(0, 16):""
                node.end_time = node.end_time != null? node.end_time.slice(0, 16): ""
            }
            commit('setCurrentTravelListInfo', res.data)
            return res
        }
    },
    async getTravelListNodes({ commit }, travel_list_id) {
        let res = await api.get_travel_lists_nodes(travel_list_id)
        if (res.status === 200) {
            commit('setCurrentTravelListNodes', res.nodes)
        }
    },
    async getTravelCheckins({ commit }, travel_list_id) {
        let res = await api.get_travel_lists_node_checkins(travel_list_id)
        if (res.data.status === 200) {
            commit('setTravelCheckins', res.data)
        }
    },
    async getCurrentTourBackup({commit}, travel_list_id) {
        let res = await api.get_archive_info(travel_list_id)
        if(res.status === 200) {
            commit('setCurrentTourBackup', res.data)
            return res.data
        }
    },
    async resetState({ commit }) {
        commit('resetState')
    }
}

const mutations = {
    setAllTravelLists(state, travel_lists) {
        state.travel_lists = travel_lists
    },
    setUnStartedTours(state, travel_lists) {
        state.unStartedTours = travel_lists
    },
    setOnGoingTours(state, travel_lists) {
        state.onGoingTours = travel_lists
    },
    setFinishedTours(state, travel_lists) {
        state.finishedTours = travel_lists
    },
    setCanceledTours(state, travel_lists) {
        state.canceledTours = travel_lists
    },
    setCurrentTravelListInfo(state, info) {
        state.current_travel_list_info = info
    },
    setCurrentTravelListNodes(state, nodes) {
        state.current_travel_list_nodes = nodes
    },
    setTravelCheckins(state, checkins) {
        state.current_travel_checkins = checkins
    },
    setCurrentTourBackup(state, backup) {
        state.current_backup_info = backup
    },
    setPageInfo(state, info) {
        state.page_info = info
    },
    resetState(state) {
        state.travel_lists = []
        state.current_travel_list_info = {}
        state.current_travel_list_nodes = [],
        state.current_travel_checkins = [],
        state.unStartedTours = [],
        state.onGoingTours = [],
        state.finishedTours = [],
        state.canceledTours = []
    }
}

const namespaced = true

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced
}