import request from '@/services/request'
const apis = {
    //游客登录
    sing_in(user) {
        return request({
            url: '/Login/login',
            method: 'post',
            data: user
        })
    },
    send_sms(mobile) {
        return request({
            url: '/Login/sms_codes',
            method: 'post',
            data: { mobile: mobile }
        })
    },
    travel_lists(status) {
        return request({
            url: '/travel_lists',
            method: 'get',
            params: { status: status }
        })
    },
    travel_lists_info_by_id(id) {
        return request({
            url: '/travel_lists/info',
            method: 'get',
            params: { travel_list_id: id }
        })
    },
    travel_lists_add_tourist(id) {
        return request({
            url: '/travel_lists/add_tourist',
            method: 'put',
            data: { travel_list_id: id }
        })
    },
    travel_lists_archive_info(data) {
        return request({
            url: '/travel_lists/archive_infos',
            method: 'post',
            data: data
        })
    },
    update_travel_lists_archive_info(data) {
        return request({
            url: `/archive_infos/${data.id}`,
            method: 'put',
            data: { tourist_archive_info: data }
        })
    },
    get_travel_lists_nodes(id) {
        return request({
            url: '/travel_lists/nodes',
            method: 'get',
            params: { travel_list_id: id }
        })
    },
    get_archive_info(id) {
        return request({
            url: '/archive_infos/archive_info',
            method: 'get',
            params: { travel_list_id: id }
        })
    },
    notifications(type, page) {
        return request({
            url: '/notifications',
            method: 'get',
            params: { notification_type: type, page: page }
        })
    },
    notifications_show(id) {
        return request({
            url: `/notifications/show`,
            method: 'get',
            params: { relation_id: id }
        })
    },
    feedbacks(params) {
        return request({
            url: '/feed_backs',
            method: 'get',
            params: params
        })
    },
    feedback_tags() {
        return request({
            url: '/feed_backs/tag_lists',
            method: 'get',
        })
    },
    feedback_show(params) {
        return request({
            url: '/feed_backs/show',
            method: 'get',
            params: params
        })
    },
    feedback_info(id) {
        return request({
            url: '/feed_backs/info',
            method: 'get',
            params: { id: id }
        })
    },
    send_feedback(data) {
        return request({
            url: '/feed_backs',
            method: 'post',
            data: data
        })
    },
    guide(id) {
        return request({
            url: `/guide`,
            method: 'get',
            params: { id: id }
        })
    },
    agency(id) {
        return request({
            url: `/agency`,
            method: 'get',
            params: { id: id }
        })
    },
    visitor_info() {
        return request({
            url: `/visitors/info`,
            method: 'get',
        })
    },
    visitor_update(data) {
        return request({
            url: `/visitors/update`,
            method: 'put',
            data: data
        })
    },
    visitor_change_password(data) {
        return request({
            url: `/visitors/change_password`,
            method: 'put',
            data: data
        })
    },
    visitors_count_info() {
        return request({
            url: "/visitors/count_info",
            method: 'get'
        })
    },
    yiqing_info() {
        return request({
            url: "/ncov/ncov_info ",
            method: 'get'
        })
    },
    get_yi_qing_high_risk() {
        return request({
            url: "/ncov/high_risk ",
            method: 'get'
        })
    }
}

export default apis