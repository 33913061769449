import axios from 'axios' // 引入axios
// import qs from 'qs'
import { Toast } from 'vant';
import cookies from "../utils/cookies";
import { baseBackEndUrl } from '@/utils/constants'
// 创建axios实例
const service = axios.create({ // 对axios进行基础匹配
    baseURL: `${baseBackEndUrl}visitor/h5/v1/`, //测试地址
    // baseURL: 'https://www.wenlvanquan.com:9292/visitor/h5/v1/', //正式地址
    // baseURL: 'http://192.168.31.249:3000/boss/h5/v1/bosses', //测试地址
    timeout: 5000,
    withCredentials: false,
    validateStatus: function(status) {

        if (status == 401) {
            Toast.fail('登录过期');
        }
        return status >= 200 && status < 300 // 默认的
    }
})

// 设置请求拦截器
service.interceptors.request.use(config => {
        const token = cookies.get('token')
        config.headers = {
                'token': token,
                // 'Content-Type': 'application/x-www-form-urlencoded',
                'Content-Type': 'application/json',
                'crossDomain': false
            }
            // config.data = qs.stringify(config.data)
        if (cookies.get('token')) {
            config.headers['Authorization'] = cookies.get('token')
        }
        // Toast.loading({
        //     forbidClick: true,
        //     loadingType: 'spinner',
        // });
        return config
    }, error => {
        Toast.fail('请求失败!')
        return Promise.reject(error)
    })
    // 设置响应拦截器
service.interceptors.response.use(data => { // 响应成功关闭loading
    if (data.data === null) {
        return null;
    }

    if (data) {
        const method = data.config.method
        if ((data.data.status === 200 || data.data.status === 201) && (method === 'post' || method === 'put' || method === 'delete')) {
            Toast.success({
                message: '操作成功',
                duration: 3000
            })
        } else if(data.status !== 200){
            Toast(data.data.message)
        }
        return data.data
    } else {}

}, error => {
    Toast(error.response.data.message)
    return Promise.reject(error)
})

export default service