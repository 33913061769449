<template>
  <div id="app">
    <!-- <transition name="van-slide-right">
      <router-view />
    </transition> -->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- <transition name="van-slide-right"> -->
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    <!-- </transition> -->
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style>
@import "./assets/css/style.css";
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  background-image: linear-gradient(
179deg
, #6C94F6 0%, #436EF0 16%, #3A5BE9 100%);
 height: 100vh;
  width: 100vw;
  background-size: 100%;
}
</style>

